import React from "react"
import './index.scss'
import logo from "../images/new-logo.svg"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Projects from "./projects"
import GraphicDesign from "./graphic-design"
import About from "./about"
import Contact from "./contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="center-logo">
      <img src={logo} className="App-logo" alt="logo" />
    </section>
    <section id="projects">
      <Projects />
    </section>
    <section id="graphic-design">
      <GraphicDesign />
    </section>
    <section id="about">
      <About />
    </section>
    <section id="contact">
      <Contact />
    </section>
  </Layout>
)

export default IndexPage
