import React from "react"

const About = () => (
  <>
    <h1>About</h1>
    <p className="lead">
      Hi, my name is Nathan.
      <br />
      <br />
      I'm a UX Designer and Software Engineer.
      <br />
      <br />
      I design and build for the modern web, inclusive of all users and devices.
      <br />
      <br />
      I have extensive experience working with design systems: from
      understanding use-cases and reqirements, to sketching a new component, to
      then recreating it in Sketch, and finally building it and deploying it to
      be used across an enterprise.
      <br />
      <br />
      The design tools I'm most familiar with include: Photoshop, Sketch and
      InVision. On the development front, I'm able to create using React,
      JavaScript, .NET Core, SASS and CSS and I can write UI tests with Selenium
      and BDD tests with Cucumber.
      <br />
      <br />
      I am at home when solving complex UX problems with multiple moving parts.
      I look at problems from different perspectives in order to understand and
      empathise with various users.
      <br />
      <br />
      Most importantly, every single pixel matters to me.
    </p>
  </>
)

export default About
