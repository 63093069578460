import React from "react"
import {
  FaCode,
  FaGamepad,
  FaBook,
  FaExternalLinkSquareAlt,
  FaImage,
} from "react-icons/fa"

import covid19Screenshot from "../images/covid.png"
import authoritiesScreenshot from "../images/authorities-screenshot.png"
import cbaLogo from "../images/cba-grey.png"
import helpView from "../images/helpview-small.jpg"
import helpViewLarge from "../images/helpview.jpg"

const Projects = () => (
  <>
    <h1>Projects</h1>
    <p className="lead">
      Here are the recent projects that I've worked on. <br />I love building
      new things and tackling problems with good design.
    </p>
    <div className="projects-list">
      <div className="project">
        <div className="preview-img">
          <img src={covid19Screenshot} alt="COVID-19 Statistics screenshot" />
        </div>
        <div className="project-description">
          <h2>
            COVID-19 Statistics
            <span className="sub-header">July - Sep 2020</span>
          </h2>
          <p>
            <strong>Why?</strong> Existing maps showing the COVID-19 outbreak
            were bloated and not responsive. We wanted to create something
            minimalistic that would work on all devices.
          </p>
          <p>
            <strong>How?</strong> Using Mathdroid's COVID-19 API and
            react-simple-maps, a choropleth map was built and mapped to the
            data.
          </p>
          <p>
            <strong>Technologies: </strong> React and Material UI
          </p>
          <div className="links">
            <a
              className="button-icon"
              href="https://cov19map.netlify.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGamepad />
              App
            </a>
            <a
              className="button-icon"
              href="https://github.com/preetycool/covid19"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaCode />
              Code
            </a>
          </div>
        </div>
      </div>
      <div className="project">
        <div className="preview-img">
          <img
            src={authoritiesScreenshot}
            alt="NetBank Authorities screenshot"
          />
        </div>
        <div className="project-description">
          <h2>
            NetBank - Authorities
            <span className="sub-header">November 2019 - July 2020</span>
          </h2>
          <p>
            <strong>Why?</strong> Digitalising the process of removing
            authorised operators from NetBank business accounts. Saves customers
            from needing to fill out and mail a paper form, or having to go into
            a branch.
          </p>
          <p>
            <strong>How?</strong> My team built and maintained back-end
            processes so that the end-to-end fulfillment of the process is
            successful. We also built and updated UI components to ensure they
            were responsive and accessible.
          </p>
          <p>
            <strong>Technologies: </strong> React, .NET Core, Selenium, Kafka,
            Splunk and much more.
          </p>
          <div className="links">
            <a
              className="button-icon"
              href="https://www.commbank.com.au/digital/authorities"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaExternalLinkSquareAlt />
              Link
            </a>
            <a
              className="button-icon"
              href="https://www.commbank.com.au/support.business.remove-access-to-your-business-accounts.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaBook />
              Docs
            </a>
          </div>
        </div>
      </div>
      <div className="project">
        <div className="preview-img">
          <img src={cbaLogo} alt="Commonwealth Bank scribbled logo" />
        </div>
        <div className="project-description">
          <h2>
            CommSee Modernisation
            <span className="sub-header">February - November 2019</span>
          </h2>
          <p>
            <strong>Why?</strong> The CommSee CRM platform was developed in
            2006, and many (thousands) of its components and processes needed to
            be modernised. I was a part of the UX team in charge of the
            modernisation. The goal was to increase ease-of-use for the 30,000+
            staff that used this system.
          </p>
          <p>
            <strong>How?</strong> Using a modern, custom-built design system,
            our team researched, user-tested, designed then built the modernised
            screens and flows. I was responsible for the UX and visual design of
            11 projects, creating wireframes and interactive prototypes in
            Sketch and InVision. I also managed and updated the design system
            and its internal web portal, ensuring we had reusable patterns and
            best practices for designers and developers.
          </p>
          <p>
            <strong>Technologies: </strong> Sketch, InVision and React
          </p>
          <div className="links">
            <p>
              <em>No links or screenshots as it's proprietary software.</em>
            </p>
          </div>
        </div>
      </div>
      <div className="project">
        <div className="preview-img">
          <img src={helpView} alt="HelpView poster" />
        </div>
        <div className="project-description">
          <h2>
            HelpView
            <span className="sub-header">September - October 2017</span>
          </h2>
          <p>
            <strong>Why?</strong> University project to build a prototype for an
            inclusive transportation and navigation app.
          </p>
          <p>
            <strong>How?</strong> Completed an end-to-end UX process: gathered
            requirements, created personas, interviewed users, created a paper
            prototype, ran a usability test and iterated the design in Balsamiq.
          </p>
          <p>
            <strong>Technologies: </strong> Balsamiq and Photoshop
          </p>
          <div className="links">
            <a
              className="button-icon"
              href={helpViewLarge}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaImage />
              Poster
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default Projects
