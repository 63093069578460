import React from "react"
import { FaEnvelope, FaLinkedin, FaGithub } from "react-icons/fa"

const Contact = () => (
  <>
    <h1>Contact</h1>
    <p className="lead">
      Hit me up if you want to chat about good design or complain about bad
      design. <br />
      I'm also open to new challenges and problems to solve.
    </p>
    <div className="contact">
      <a
        className="button-icon"
        href="mailto:hello@nathanzhen.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaEnvelope />
        hello@nathanzhen.com
      </a>
      <a
        className="button-icon"
        href="https://www.linkedin.com/in/nathanzhen/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedin />
        LinkedIn
      </a>
      <a
        className="button-icon"
        href="https://github.com/yaburi"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaGithub />
        GitHub
      </a>
    </div>
  </>
)

export default Contact
